/* eslint-disable linebreak-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';

import { Fade } from 'react-awesome-reveal';

// eslint-disable-next-line import/no-extraneous-dependencies
// import {
//   Tab, Tabs, TabList, TabPanel,
// } from 'react-tabs';

export default function AllPortfolio({ data }) {
  return (
    <Fade bottom>
      <section className="container mx-auto">
        <div className="flex flex-col sm:flex-row justify-items-center">
            {
              data.map((item, index) => (
                <Fade bottom delay={500 * index} key={index}>
                    <div className="group rounded-2xl shadow-xl w-auto w-11/12 m-3 transform transition duration-500 hover:scale-110 portofolio-card">
                      <div className="relative">
                        <img src={item.imageUrl} alt="Portfolio" className="rounded-t-2xl z-0" />
                      </div>
                      <div className="py-4">
                        <h2 className="text-theme-blue text-center text-xl">{item.title}</h2>
                        <p className="font-light text-gray-400 text-center">{item.type}</p>
                      </div>
                    </div>
                </Fade>
              ))
            }
          </div>
      </section>
    </Fade>
  );
}
