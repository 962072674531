/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Button from "../elements/Button";

export default function Service({ data }) {
  return (
    <div className="bg-gray-50">
      <div className="container mx-auto pt-20 pb-28">
        <Fade direction="right" triggerOnce>
          <h1 className="text-5xl text-theme-blue text-center font-bold">Our Services</h1>
        </Fade>
        <Fade direction="left" triggerOnce>
          <p className="font-light text-lg text-gray-400 text-center mb-12">
            We are ready to scale up your business with our great service.
          </p>
        </Fade>

        <div className="grid grid-rows-3 px-10 gap-8 sm:grid-cols-3 sm:grid-rows-1 sm:gap-6 xl:gap-16">
          {data.map((item) => (
            <Fade direction={item.animation} delay={500} key={item.id} triggerOnce>
              <div className="bg-white group rounded-2xl shadow-2xl border border-light-theme-purple transform transition duration-500 hover:scale-105 flex flex-col h-full">
                
                {/* Image at the top */}
                <img src={item.imageUrl} alt="Service" className="w-full rounded-t-2xl" />
                
                {/* Title in the middle */}
                <div className="flex-grow p-5 text-center">
                  <h2 className="text-theme-blue text-center text-xl py-7">{item.title}</h2>
                </div>

                {/* Button at the bottom */}
                <Button
                  type="link" 
                  href={`/Service/${item.id}`}
                  className="flex items-center justify-center w-full py-5 text-black text-xl bg-ql rounded-b-2xl shadow-2xl hover:bg-dark-theme-purple hover:text-white transition duration-200"
                >
                  Learn More
                </Button>
                
              </div>
            </Fade>
          ))}
        </div>

      </div>
    </div>
  );
}
