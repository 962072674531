/* eslint-disable import/extensions */

// Services Img Imports
import Web from '../assets/images/Services/Web.png';
import Mobile from '../assets/images/Services/Mobile.png';
import UIUX from '../assets/images/Services/Design.png';
import Consulting from '../assets/images/Services/Consulting.jpeg';
import Server from '../assets/images/Services/Server.png';
import CurrentSupport from '../assets/images/Services/CurrentSupport.png';

// Portfolio Img Imports
import Aura from '../assets/images/Portfolio/Aura.png';
import ManagementApp from '../assets/images/Portfolio/ManagementApp.png';

// Advantages
import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

// Testimonials
import Sasha from '../assets/images/Testimonials/Sasha.jpg';
import Reiner from '../assets/images/Testimonials/Reiner.jpg';
import Kruger from '../assets/images/Testimonials/Kruger.jpg';

// TeamMembers
import azure from '../assets/images/TeamMembers/azure.png';
import next from '../assets/images/TeamMembers/next.png';
import sql from '../assets/images/TeamMembers/sql.png';
import bi from '../assets/images/TeamMembers/bi.png';
import bootstrap from '../assets/images/TeamMembers/bootstrap.jpeg';
import materialui from '../assets/images/TeamMembers/materialui.jpeg';
import netcore from '../assets/images/TeamMembers/net.png';
import postgresql from '../assets/images/TeamMembers/postgresql.jpeg';
import react from '../assets/images/TeamMembers/react.png';

export const Services = [
  {
    id: '1',
    title: 'Software Development',
    imageUrl: Web,
    animation: 'left',
    link: "/Service/1",
    description: "Our Software Development services are tailored to meet the unique needs of your business, whether you require a custom web application to streamline operations, a specialized CRM to manage client interactions, or a landing page that captures your brand’s essence. With expertise across front-end and back-end development, we create robust, scalable solutions that grow with you, ensuring seamless functionality and engaging user experiences.",
    services: [
      { 
        title: 'Custom Web Application Development', 
        description: 'Build web applications tailored to your business needs, enhancing productivity and user engagement.',
      },
      { 
        title: 'CRM Solutions', 
        description: 'Design custom Customer Relationship Management systems for efficient client tracking and relationship management.',
      },
      { 
        title: 'Enterprise Portals and Dashboards', 
        description: 'Create data-rich, interactive portals and dashboards for real-time insights and operational oversight.', 
      },
      { 
        title: 'Custom Landing Page Websites', 
        description: 'Develop branded landing pages that convey your company’s message and drive conversions.', 
      },
      { 
        title: 'Windows Desktop Applications', 
        description: 'Build secure and user-friendly Windows applications for desktop environments, optimized for your specific workflows.', 
      },
      { 
        title: 'API Development & Integration', 
        description: 'Design and implement APIs for seamless integration with other platforms and services.', 
      },
      { 
        title: 'Legacy Software Modernization', 
        description: 'Update outdated software systems to improve performance, security, and user experience.', 
      },
    ],
  },
  {
    id: '2',
    title: 'IT Management',
    imageUrl: Mobile,
    animation: 'up',
    link: '/Service/2',
    description: "Our IT Management services provide the technical foundation your business needs to operate efficiently and securely. From network management to smart office solutions, we handle the complexities of IT so you can focus on growth. Our services include specialized solutions for WiFi management, door lock systems, and more, ensuring reliable, secure, and scalable infrastructure to support your business operations.",
    services: [
      { 
        title: 'Network & WiFi Management', 
        description: 'Design, deploy, and manage secure and high-performance WiFi networks tailored to your organization’s needs.', 
      },
      { 
        title: 'Smart Door Lock Management', 
        description: 'Implement and maintain smart door lock systems for enhanced security and access control across your facilities.', 
      },
      { 
        title: 'IT Infrastructure Management', 
        description: 'Oversee and optimize your entire IT infrastructure, ensuring efficiency, security, and scalability.', 
      },
      { 
        title: 'Server & Storage Solutions', 
        description: 'Set up and manage reliable server and storage solutions that support your data needs and business growth.', 
      },
      { 
        title: 'Device & Endpoint Management', 
        description: 'Manage and secure devices across your organization to ensure seamless operations and data protection.', 
      },
      { 
        title: 'Network Security Monitoring', 
        description: 'Continuously monitor and safeguard your network against threats, ensuring data security and compliance.', 
      },
      { 
        title: 'Help Desk & Support Services', 
        description: 'Provide responsive IT support to troubleshoot issues and maintain productivity for your team.', 
      },
      { 
        title: 'Backup & Disaster Recovery', 
        description: 'Implement backup and recovery plans to protect data and ensure continuity in case of unexpected disruptions.', 
      },
    ],
  },
  {
    id: '3',
    title: 'Data Analytics',
    imageUrl: UIUX,
    animation: 'right',
    link: '/Service/3',
    description: "Our Data Analytics services transform raw data into actionable insights, empowering your business to make data-driven decisions with confidence. From data visualization to advanced analytics, we offer comprehensive solutions to unlock the value in your data. Using tools like Power BI, we deliver interactive reports and dashboards that provide real-time insights to support strategic growth.",
    services: [
      { 
        title: 'Data Visualization & Reporting', 
        description: 'Create interactive reports and dashboards to visualize key metrics and trends, enabling quick, informed decision-making.', 
      },
      { 
        title: 'Business Intelligence (BI) Solutions', 
        description: 'Design and implement BI systems that consolidate data and deliver insights across your organization.', 
      },
      { 
        title: 'Predictive Analytics', 
        description: 'Use data modeling to forecast trends and support strategic planning, enhancing your ability to anticipate changes.', 
      },
      { 
        title: 'Data Mining & Pattern Recognition', 
        description: 'Analyze large datasets to identify patterns, correlations, and anomalies that reveal opportunities for optimization.', 
      },
      { 
        title: 'Power BI Implementation & Customization', 
        description: 'Build custom Power BI dashboards tailored to your business needs, delivering accessible insights from complex data.', 
      },
      { 
        title: 'Data Quality Assessment & Cleaning', 
        description: 'Ensure your data is accurate, consistent, and ready for analysis, improving the reliability of your insights.', 
      },
      { 
        title: 'KPI Development & Monitoring', 
        description: 'Define and track key performance indicators (KPIs) to monitor progress and measure success across your operations.', 
      },
      { 
        title: 'ETL (Extract, Transform, Load) Services', 
        description: 'Streamline the process of extracting, transforming, and loading data to ensure efficient data integration and access.', 
      },
    ],

  },
  {
    id: '4',
    title: 'Software Consulting',
    imageUrl: Consulting,
    animation: 'left',
    link: '/Service/4',
    description: "Our Software Consulting services are designed to guide your business through digital transformation, optimize your technology stack, and align software solutions with your strategic goals. We provide expert advice, in-depth analysis, and tailored recommendations to ensure your software investments deliver maximum value and drive sustainable growth.",
    services: [
      { 
        title: 'Technology Strategy & Roadmap', 
        description: 'Develop a clear, actionable technology strategy that aligns with your business objectives and sets a path for future growth.', 
      },
      { 
        title: 'Software Architecture Consulting', 
        description: 'Design scalable and robust software architectures to support current needs and future expansion.', 
      },
      { 
        title: 'System Integration', 
        description: 'Integrate new and existing systems seamlessly, ensuring efficient data flow and interoperability across platforms.', 
      },
      { 
        title: 'Process Automation Consulting', 
        description: 'Identify and implement automation opportunities to streamline workflows and enhance productivity.', 
      },
      { 
        title: 'Cloud Strategy & Migration', 
        description: 'Evaluate and implement cloud solutions that optimize performance, scalability, and cost efficiency.', 
      },
      { 
        title: 'Digital Transformation Advisory', 
        description: 'Provide guidance on transforming business processes and customer experiences through digital solutions.', 
      },
      { 
        title: 'IT Infrastructure Assessment', 
        description: 'Conduct a thorough assessment of your IT infrastructure to identify areas for improvement and optimization.', 
      },
      { 
        title: 'Risk Management & Compliance', 
        description: 'Help ensure your systems meet regulatory standards and mitigate risks to protect data and maintain compliance.', 
      },
    ],
  },
  {
    id: '5',
    title: 'Database Management',
    imageUrl: Server,
    animation: 'up',
    link: '/Service/5',
    description: "Our Database Management services ensure your data is secure, organized, and optimized for performance. From designing robust database architectures to ongoing maintenance, we provide end-to-end solutions to support your business's data needs. Whether scaling your data infrastructure or integrating with other systems, we create reliable and efficient data solutions that grow with your business.",
    services: [
      { 
        title: 'Database Design & Architecture', 
        description: 'Develop a tailored database structure that supports your business operations and scales with growth.', 
      },
      { 
        title: 'Data Migration', 
        description: 'Ensure smooth and secure data transfer from legacy systems to new database environments without data loss or downtime.', 
      },
      { 
        title: 'Database Optimization', 
        description: 'Enhance database performance through indexing, query optimization, and other best practices for efficient data retrieval.', 
      },
      { 
        title: 'Backup & Recovery Solutions', 
        description: 'Implement robust backup and recovery plans to protect your data and ensure business continuity in case of data loss.', 
      },
      { 
        title: 'Database Security Management', 
        description: 'Ensure your data is protected with advanced security protocols and regular vulnerability assessments.', 
      },
      { 
        title: 'Data Integration', 
        description: 'Integrate databases with applications, APIs, and external data sources for seamless data flow across platforms.', 
      },
      { 
        title: 'Performance Monitoring & Maintenance', 
        description: 'Regularly monitor and maintain your database for optimal performance, stability, and reliability.', 
      },
      { 
        title: 'Data Warehousing & Analytics', 
        description: 'Develop data warehouses to centralize and analyze large datasets, providing insights to drive informed decisions.', 
      },
    ],
  },
  {
    id: '6',
    title: 'Current System Support',
    imageUrl: CurrentSupport,
    animation: 'right',
    link: '/Service/6',
    description: "Our Current System Support services ensure that your existing technology infrastructure runs smoothly and efficiently. We provide dedicated support to maintain, troubleshoot, and enhance your current systems, allowing you to maximize the value of your technology investments. With our proactive approach, we help prevent issues before they arise and ensure your systems remain reliable and secure.",
    services: [
      { 
        title: 'System Monitoring & Maintenance', 
        description: 'Continuously monitor and maintain your systems to ensure optimal performance and identify potential issues early.', 
      },
      { 
        title: 'Technical Troubleshooting & Issue Resolution', 
        description: 'Resolve technical issues swiftly to minimize downtime and keep your operations running smoothly.', 
      },
      { 
        title: 'Software Updates & Patch Management', 
        description: 'Apply regular software updates and patches to keep systems secure and up-to-date.', 
      },
      { 
        title: 'Performance Optimization', 
        description: 'Analyze and optimize system performance, addressing bottlenecks and improving overall efficiency.', 
      },
      { 
        title: 'User Support & Training', 
        description: 'Provide support and training for users to help them utilize systems effectively and troubleshoot common issues.', 
      },
      { 
        title: 'Data Backup & Restoration', 
        description: 'Implement and manage data backup solutions to safeguard information and facilitate quick restoration when needed.', 
      },
      { 
        title: 'System Documentation & Reporting', 
        description: 'Maintain detailed documentation and regular reports on system health, performance, and maintenance activities.', 
      },
      { 
        title: 'Vendor & License Management', 
        description: 'Manage software licenses and coordinate with vendors to ensure your systems are compliant and up-to-date.', 
      },
    ],
  },
];

export const Portfolios = [
  {
    id: '1',
    title: 'WordPress Website',
    imageUrl: Aura,
    type: 'Bohr Electronics',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
  },
  {
    id: '2',
    title: 'Custom CRM Web APP',
    imageUrl: ManagementApp,
    type: 'Bohr Electronics',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
  },
];

export const Advantages = [
  [{
    title: 'Clear Communication',
    description: 'We keep you informed and involved, so there are no surprises—only results.',
    imageUrl: Communicative,
  },
  {
    title: 'Management',
    description: 'Our organized approach keeps your project on track, saving you time and resources.',
    imageUrl: Management,
  }],
  [{
    title: 'Adaptable to Your Needs',
    description: 'We adjust swiftly to meet your goals, ensuring the project always aligns with your vision.',
    imageUrl: Collaborative,
  },
  {
    title: 'Client-Focused Collaboration',
    description: "Your input shapes every step, creating a solution that truly reflects your needs.",
    imageUrl: Favorite,
  }],
];
export const Testimonials = [
  {
    id: 1,
    name: 'Sasha Rose',
    company: 'Owner, Surveyor Corps',
    testimoni: 'Thanks for Racxstudio, you guys are the best! Keep up the great work!',
    imageUrl: Sasha,
  },
  {
    id: 2,
    name: 'Kruger Khan',
    company: 'Director, Shultan Oil',
    testimoni: 'I just wanted to let you know that it’s been great working with Racxstudio.',
    imageUrl: Kruger,
  },
  {
    id: 3,
    name: 'Reiner John',
    company: 'CEO, Marley CO',
    testimoni: 'Racxstudio is so great. Thank you so much for a job well done.',
    imageUrl: Reiner,
  },
];

export const TeamMembers = [
  {
    name: 'Microsoft Azure',
    position: 'Cloud Platform',
    imageUrl: azure,
  },
  {
    name: 'Next.js',
    position: 'Web Framework',
    imageUrl: next,
  },
  {
    name: 'MS SQL',
    position: 'Database Management System',
    imageUrl: sql,
  },
  {
    name: 'PostegreSQL',
    position: 'Database Management System',
    imageUrl: postgresql,
  },
  {
    name: 'Power BI',
    position: 'Data Visualization',
    imageUrl: bi,
  },
  {
    name: 'BootStrap',
    position: 'CSS Framework',
    imageUrl: bootstrap,
  },
  {
    name: 'Material UI',
    position: 'CSS Framework',
    imageUrl: materialui,
  },
  {
    name: '.NET Core',
    position: 'Framework',
    imageUrl: netcore,
  },
 
  {
    name: 'React.js',
    position: 'Web Library',
    imageUrl: react,
  },
];
