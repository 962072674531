/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from 'react';

import brandimage from 'assets/images/Logo 2.png';

import Button from '../elements/Button';

export default function BrandIcon() {
  return (
      <Button
        className=""
        type="link"
        href="/"
      >
        
        <div className="flex items-center space-x-3 xl:mb-5">
          <img src={brandimage} alt="logo" className="w-[80px] h-[80px] rounded-xl z-0" />  {/* Adjust size with w-8 and h-8 */}
          <p className="text-theme-blue text-4xl font-medium">
            QuirkLab<span className="text-ql"> Consulting</span>
          </p>
        </div>
        
      </Button>
  );
}
