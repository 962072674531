/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from 'react';

import BrandIcon from './BrandIcon';

export default function Footer() {
  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6">
      <div className="container mx-auto px-4"> {/* Add padding to ensure content doesn’t touch edges on mobile */}
        <div className="flex flex-col sm:flex-row mt-7 justify-center space-y-6 sm:space-y-0"> {/* Add spacing between sections on mobile */}
          
          <div className="w-full sm:w-1/3 flex flex-col items-center sm:items-start sm:ml-16 sm:mr-8">
            <BrandIcon />
            <p className="w-full text-lg text-gray-400 font-light text-center sm:text-left">
              Your Vision
              <br />
              Our Innovation
            </p>
          </div>
          
          <div className="w-full sm:w-1/3 flex flex-col items-center sm:items-start mt-0 sm:mt-0 sm:ml-16 sm:mr-5">
            <h1 className="text-lg text-theme-blue pt-4 pb-2 text-center sm:text-left">
              Office
            </h1>
            <p className="text-lg text-gray-400 font-light text-center sm:text-left">
              info@ql-consulting.com
            </p>
            <p className="text-lg text-gray-400 font-light text-center sm:text-left">
              Toronto, Ontario, Canada
            </p>
          </div>
        </div>
        
        <div className="text-center mt-7">
          <p className="text-lg text-gray-400 font-light">
            Copyright 2024 - All rights reserved - QuirkLab Consulting
          </p>
        </div>
      </div>
    </div>
  );
}
